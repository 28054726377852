/* Swiper Special Style */

.swiper-button-disabled {
  opacity: 0 !important;
}
.swiper-button-next {
  right: var(--swiper-navigation-sides-offset, 20px) !important;
  scale: 1.4;
}
.swiper-button-prev {
  left: var(--swiper-navigation-sides-offset, 20px) !important;
  scale: 1.4;
}

#testimonials .swiper-button-next:after,
.swiper-rtl {
  content: "\203A" !important;
  color: white !important;
}

#testimonials .swiper-button-prev:after {
  content: "\2039" !important;
  color: white !important;
}

#testimonial_videos .swiper-button-prev:after,
#testimonial_videos .swiper-button-next:after {
  color: #111 !important;
  scale: 0.4;
}

.swiper-pagination {
  opacity: 0;
  position: relative;
  z-index: -100;
}

/*  */

/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400&family=Yantramanav:wght@500;700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400&family=Yantramanav:wght@500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;
/* styles.css */

.button-primary {
  @apply text-white py-3 px-5 rounded-lg outline-gray-500 border-transparent;
}

.button-tertiary {
  @apply rounded-full border-[2px] border-gray-950 px-4 lg:px-8 py-2 font-semibold capitalize;
}

.button_tertiary-white {
  @apply rounded-full border-[2px] border-gray-100 px-8 py-2 font-semibold capitalize;
}

.heading-subtitle {
  @apply text-faded-black capitalize font-semibold text-3xl;
}

h3 {
  @apply text-2xl md:text-5xl font-semibold text-dark-chocolate;
}

p {
  @apply lg:text-lg;
}

.text-tertiary {
  @apply px-2 text-3xl md:text-4xl font-semibold text-black;
}
.text-tertiary-white {
  @apply px-2 text-3xl md:text-4xl font-semibold text-white;
}

.text-input {
  @apply border p-2 focus:border-2 focus:border-primary-light outline-none w-full rounded-lg my-2;
}

.select-input {
  @apply outline-none w-full px-4 py-2 mt-1 border border-gray-300 shadow-sm shadow-gray-300 rounded-md focus:outline-none focus:ring focus:border-primary  bg-light-gray font-normal;
}

.text-input {
  @apply py-3 px-4;
}

.container {
  @apply flex flex-col;
}

.image-cover {
  @apply w-full h-full object-cover;
}

.page-header {
  @apply my-14;
}
.custom-box-shadow {
  @apply shadow-lg; /* You can adjust the size or add other shadow properties here */
  box-shadow: 6px 6px 1px 1px black;
}

.custom-box_shadow-light {
  @apply shadow-lg; /* You can adjust the size or add other shadow properties here */
  box-shadow: 8px 8px 1px 1px white;
}

.place-holder {
  @apply w-full overflow-hidden px-4 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300  bg-light-gray;
}
.place-holder_select {
  @apply w-[96%] overflow-hidden px-4 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300  bg-light-gray;
}

html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font-family: "Lato", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  max-width: 3064px;
  margin: 0 auto !important;
  line-height: 1.6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Yantramanav", sans-serif;
}

.select {
  cursor: pointer;
  position: relative;
  border-radius: 0.5rem;
  border-radius: 0.5rem;
  background: #871414;
}

/* select {
  all: unset;
  position: relative;
  display: block;
  width: 100%;
  padding: 1rem 5rem 1rem 0.02rem;
  border-radius: 0.5rem;
} */

.first {
  all: unset;
}

#testimonials {
  scrollbar-width: "none";
  scrollbar-color: rgba(249, 249, 249, 0);
  scrollbar-track-color: rgba(127, 255, 212, 0);
}

select {
  all: unset;
  display: block;
}

@layer base {
  body {
    font-size: clamp(45%, 62.5%, 74.5%);
    font-size: 14px;
  }
}

@layer utilities {
  .list-horizontal {
    flex: 1;
  }
  .list-horizontal:not(:last-child) {
    border-right: 1px solid gray;
  }
}

#map {
  height: 70vh;
  width: 80vw;
}
/* 


.leaflet-zoom-animated{
  background: #8714144e;
} */

.leaflet-overlay-pane path {
  /* fill: #098d04; */
  stroke: rgba(18, 149, 31, 0.183);
  stroke-width: 2;
  stroke-opacity: 1;
  /* fill-opacity: 0.7; */
}